import React, { useState } from "react";
import { Navbar } from "../Navbar";
import SessionTypeList from "../SessionType/SessionTypeList";
import { Avatar, Box, Container } from "@mui/material";
import CoachList from "../Coach/CoachList";
import SessionList from "../Sessions/SessionList";
import Vmax from "../../assets/VMax ShapeUp Studio Logo.png";
import { Button } from "@mui/material";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { axiosFetch } from "../../Utils/AxiosFetch";
import { useSelectedCountry } from "../../store/SelectedCountryContext";
import { countries } from "../../Utils/countries";
import "../../components/commonStyle.css";

const sortedCountryCodes = countries.sort((a, b) =>
  a.label.localeCompare(b.label)
);

export default function Homepage() {
  const [tabValue, setTabValue] = useState(1);
  const { selectedCountry, setSelectedCountry } = useSelectedCountry();

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const toggleTab = (param) => {
    setTabValue(param);
  };

  const handleSignOut = async () => {
    try {
      const response = await axiosFetch({
        url: "/leadSignout",
        method: "post",
      });

      if (response.status === 200) {
        localStorage.removeItem("authTocken");
        window.location.reload();
      }
    } catch {}
  };

  //const isAndroid = navigator.userAgent.toLowerCase().includes("android");
  const isChrome = navigator.userAgent.toLowerCase().includes("chrome");

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <Box>
        <div
          className="header-logo-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "100px" }}>
            <img src={Vmax} alt="logo" style={{ width: "100%" }} />
          </div>
          <Box sx={{ width: "100px" }}>
            <FormControl
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCountry}
                onChange={handleChange}
                renderValue={(selected) => {
                  const country = countries.find((c) => c.code === selected);
                  return country ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        variant="square"
                        src={`https://flagcdn.com/w40/${country.flag.toLowerCase()}.png`}
                        sx={{ width: 24, height: 16, marginRight: 1 }}
                      />
                      {country.code}
                    </div>
                  ) : (
                    ""
                  );
                }}
              >
                {sortedCountryCodes.map((country) => (
                  <MenuItem
                    key={country.code}
                    value={country.code}
                    sx={{ display: "flex" }}
                  >
                    <Avatar
                      variant="square"
                      src={`https://flagcdn.com/w40/${country.flag.toLowerCase()}.png`}
                      sx={{ width: 24, height: 16, marginRight: 1 }}
                    />
                    {country.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* <div>
            {isChrome && (
              <Button
                onClick={handleSignOut}
                variant="outlined"
                color="inherit"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#2d2d2d;",
                  color: "#fff",
                  // padding: "0px 10px",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: "#2d2d2d;",
                  },
                }}
              >
                Sign out
              </Button>
            )}
          </div> */}
        </div>

        <Navbar />
      </Box>
    </Container>
  );
}
