export const countries = [
  {
    code: "US",
    label: "United States",
    flag: "US",
    timezone: "America/New_York",
  },
  { code: "IN", label: "India", flag: "IN", timezone: "Asia/Kolkata" },
  {
    code: "GB",
    label: "United Kingdom",
    flag: "GB",
    timezone: "Europe/London",
  },
  { code: "FR", label: "France", flag: "FR", timezone: "Europe/Paris" },
  { code: "CA", label: "Canada", flag: "CA", timezone: "America/Toronto" },
  { code: "AU", label: "Australia", flag: "AU", timezone: "Australia/Sydney" },
  { code: "DE", label: "Germany", flag: "DE", timezone: "Europe/Berlin" },
  { code: "IT", label: "Italy", flag: "IT", timezone: "Europe/Rome" },
  { code: "ES", label: "Spain", flag: "ES", timezone: "Europe/Madrid" },
  { code: "BR", label: "Brazil", flag: "BR", timezone: "America/Sao_Paulo" },
  { code: "MX", label: "Mexico", flag: "MX", timezone: "America/Mexico_City" },
  { code: "CN", label: "China", flag: "CN", timezone: "Asia/Shanghai" },
  { code: "JP", label: "Japan", flag: "JP", timezone: "Asia/Tokyo" },
  { code: "KR", label: "South Korea", flag: "KR", timezone: "Asia/Seoul" },
  { code: "RU", label: "Russia", flag: "RU", timezone: "Europe/Moscow" },
  {
    code: "ZA",
    label: "South Africa",
    flag: "ZA",
    timezone: "Africa/Johannesburg",
  },
  { code: "NG", label: "Nigeria", flag: "NG", timezone: "Africa/Lagos" },
  { code: "EG", label: "Egypt", flag: "EG", timezone: "Africa/Cairo" },
  { code: "KE", label: "Kenya", flag: "KE", timezone: "Africa/Nairobi" },
  {
    code: "AR",
    label: "Argentina",
    flag: "AR",
    timezone: "America/Argentina/Buenos_Aires",
  },
  { code: "CL", label: "Chile", flag: "CL", timezone: "America/Santiago" },
  { code: "CO", label: "Colombia", flag: "CO", timezone: "America/Bogota" },
  { code: "PE", label: "Peru", flag: "PE", timezone: "America/Lima" },
  { code: "SE", label: "Sweden", flag: "SE", timezone: "Europe/Stockholm" },
  { code: "NO", label: "Norway", flag: "NO", timezone: "Europe/Oslo" },
  { code: "FI", label: "Finland", flag: "FI", timezone: "Europe/Helsinki" },
  { code: "DK", label: "Denmark", flag: "DK", timezone: "Europe/Copenhagen" },
  { code: "PL", label: "Poland", flag: "PL", timezone: "Europe/Warsaw" },
  { code: "BE", label: "Belgium", flag: "BE", timezone: "Europe/Brussels" },
  {
    code: "NL",
    label: "Netherlands",
    flag: "NL",
    timezone: "Europe/Amsterdam",
  },
  {
    code: "LU",
    label: "Luxembourg",
    flag: "LU",
    timezone: "Europe/Luxembourg",
  },
  { code: "AT", label: "Austria", flag: "AT", timezone: "Europe/Vienna" },
  { code: "CH", label: "Switzerland", flag: "CH", timezone: "Europe/Zurich" },
  { code: "SG", label: "Singapore", flag: "SG", timezone: "Asia/Singapore" },
  { code: "MY", label: "Malaysia", flag: "MY", timezone: "Asia/Kuala_Lumpur" },
  { code: "TH", label: "Thailand", flag: "TH", timezone: "Asia/Bangkok" },
  { code: "VN", label: "Vietnam", flag: "VN", timezone: "Asia/Ho_Chi_Minh" },
  { code: "ID", label: "Indonesia", flag: "ID", timezone: "Asia/Jakarta" },
  { code: "PH", label: "Philippines", flag: "PH", timezone: "Asia/Manila" },
  { code: "PK", label: "Pakistan", flag: "PK", timezone: "Asia/Karachi" },
  { code: "BD", label: "Bangladesh", flag: "BD", timezone: "Asia/Dhaka" },
  { code: "SA", label: "Saudi Arabia", flag: "SA", timezone: "Asia/Riyadh" },
  {
    code: "AE",
    label: "United Arab Emirates",
    flag: "AE",
    timezone: "Asia/Dubai",
  },
  { code: "KW", label: "Kuwait", flag: "KW", timezone: "Asia/Kuwait" },
  { code: "QA", label: "Qatar", flag: "QA", timezone: "Asia/Qatar" },
  { code: "OM", label: "Oman", flag: "OM", timezone: "Asia/Muscat" },
  { code: "JO", label: "Jordan", flag: "JO", timezone: "Asia/Amman" },
  { code: "LB", label: "Lebanon", flag: "LB", timezone: "Asia/Beirut" },
  { code: "SY", label: "Syria", flag: "SY", timezone: "Asia/Damascus" },
  { code: "IQ", label: "Iraq", flag: "IQ", timezone: "Asia/Baghdad" },
  { code: "IL", label: "Israel", flag: "IL", timezone: "Asia/Jerusalem" },
  { code: "TR", label: "Turkey", flag: "TR", timezone: "Europe/Istanbul" },
  { code: "GR", label: "Greece", flag: "GR", timezone: "Europe/Athens" },
  { code: "PT", label: "Portugal", flag: "PT", timezone: "Europe/Lisbon" },
  { code: "HU", label: "Hungary", flag: "HU", timezone: "Europe/Budapest" },
  {
    code: "CZ",
    label: "Czech Republic",
    flag: "CZ",
    timezone: "Europe/Prague",
  },
  { code: "SK", label: "Slovakia", flag: "SK", timezone: "Europe/Bratislava" },
  { code: "RO", label: "Romania", flag: "RO", timezone: "Europe/Bucharest" },
  { code: "BG", label: "Bulgaria", flag: "BG", timezone: "Europe/Sofia" },
  { code: "HR", label: "Croatia", flag: "HR", timezone: "Europe/Zagreb" },
  { code: "RS", label: "Serbia", flag: "RS", timezone: "Europe/Belgrade" },
  { code: "UA", label: "Ukraine", flag: "UA", timezone: "Europe/Kiev" },
  { code: "BY", label: "Belarus", flag: "BY", timezone: "Europe/Minsk" },
  { code: "MD", label: "Moldova", flag: "MD", timezone: "Europe/Chisinau" },
  { code: "AM", label: "Armenia", flag: "AM", timezone: "Asia/Yerevan" },
  { code: "GE", label: "Georgia", flag: "GE", timezone: "Asia/Tbilisi" },
  { code: "AZ", label: "Azerbaijan", flag: "AZ", timezone: "Asia/Baku" },
];
