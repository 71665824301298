import { Alert, Button, Snackbar } from "@mui/material";
import { axiosFetch } from "../../Utils/AxiosFetch";

function ZoomDeepLinkButton(props) {
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{ width: "136px", textTransform: "capitalize" }}
      onClick={() =>
        handleZoomDeepLink(
          props.zoomMeetingId,
          props.zoomMeetingPassword,
          props.meetingId,
          props.sessionId,
          props.session,
          props.setOpenSnackbar,
          props.setSnackMessage,
          props.setSnackServerity
        )
      }
    >
      Join Session
    </Button>
  );
}

function handleZoomDeepLink(
  zoomMeetingId,
  zoomMeetingPassword,
  meetingId,
  sessionId,
  session,
  setOpenSnackbar,
  setSnackMessage,
  setSnackServerity
) {
  // const zoomMeetingId = '88073963757';
  // const zoomMeetingPassword = '097381';

  axiosFetch({
    url: `/programSession/${sessionId}/${meetingId}/markAttendance`,
    method: "post",
    requestConfig: {},
  })
    .then((res) => {
      if(res.data.status_code == 400){
        setOpenSnackbar(true);
          setSnackMessage(res.data.message);
          setSnackServerity("error");
       return false
      }else{
        const zoomUrlScheme = "zoomus://";
        const deepLinkPath = "zoom.us/join";
        const queryParameters = {
          confno: zoomMeetingId,
          pwd: zoomMeetingPassword,
        };
      
        const deepLinkUrl = `${zoomUrlScheme}${deepLinkPath}?${new URLSearchParams(
          queryParameters
        ).toString()}`;
      
        try {
          //window.location.href = deepLinkUrl;
          //window.location.href = "https://us06web.zoom.us/j/" + zoomMeetingId;
          window.location.href = session?.join_url;
      
          /*
              If click on the link and app is not launched within some time limit
              we will open in fallback mode
              */
          const platform = localStorage.getItem("platform");
      
          // Only for Desktop try to open in Fallback mode to a web browser
          if (platform) {
            if (platform === "desktop") {
              //https://us06web.zoom.us/j/85003565567
              // Fallback to opening in browser if the deep link fails
              setTimeout(() => {
                window.location.href = "https://us06web.zoom.us/j/" + zoomMeetingId;
              }, 2000); // Adjust the timeout value as needed
            }
            // if(platform === 'ios') {
            //     //https://us06web.zoom.us/j/85003565567
            //     // Fallback to opening in browser if the deep link fails
            //     setTimeout(() => {
            //         window.location.href = "https://apps.apple.com/us/app/zoom-one-platform-to-connect/id546505307";
            //     }, 5000); // Adjust the timeout value as needed
            // }
          }
        } catch (error) {
          console.log(">>>handleZoomDeepLink", error);
        }
      }
    })
    .catch((err) => console.log(err , 'hgurgtuhgtiuhgtruhghg'));



    // return false


}

function isZoomInstalled() {
  try {
    // Attempt to open the Zoom app using the custom URL scheme
    window.location.href = "zoomus://";
    alert("success");
    // If the above line does not throw an error,
    // assume the Zoom app is installed
    return true;
  } catch (error) {
    alert("Error");
    // An error occurred, Zoom app is not installed or scheme is not registered
    return false;
  }
}

export default ZoomDeepLinkButton;
