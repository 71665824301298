import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  colors,
} from "@mui/material";
import { styles } from "./styles";
import Vmax_Logo from "../../assets/Vmax_Logo.png";
import Login_demo from "../../assets/login_demo.jpeg";
import Fitmom_logo from "../../assets/Fitmom logo 3.png";
import { axiosFetch } from "../../Utils/AxiosFetch";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { MuiTelInput } from "mui-tel-input";
import { styled } from "@mui/material/styles";

const CustomMuiTelInput = styled(MuiTelInput)({
  "& .MuiInputBase-root": {
    // Normal state
    border: "1px solid #ccc", // Default border color
    borderRadius: "4px", // Custom border radius
    transition: "border-color 0.3s ease", // Smooth transition
    color: "#ddd",

    "&:hover": {
      // Hover state
      borderColor: "#ccc", // Border color on hover
    },

    "&.Mui-focused": {
      // Focus state
      borderColor: "#ccc", // Border color on focus
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    // Outlined variant default border
    border: "none", // Remove default border of the outlined variant
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {
  const { state } = useLocation();

  //   let { firstName, lastName, imageUrl, about, id,skillImageUrls} = state?.coachDtls;

  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState({
    value: "",
    error: false,
  });
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [openSnackbar, setopenSnackbar] = useState({
    open: false,
    serverity: "",
    message: "",
  });

  const [otpFilde, setOtpFilde] = useState(false);

  const handleChange = (index) => (event) => {
    const value = event.target.value;

    // Ensure only numerical input is accepted
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < newOtp?.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      } else if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index) => (event) => {
    if (event?.key === "Backspace" && index > 0) {
      // Move focus to the previous input on Backspace
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    } else if (
      event?.key >= "0" &&
      event?.key <= "9" &&
      index < otp?.length - 1
    ) {
      // Move focus to the next input on number input
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  useLayoutEffect(() => {
    if (localStorage.getItem("authTocken")) {
      navigate("/", {
        state: {},
      });
    }
  }, []);

  const LoginWithMobile = () => {
    if (!mobileNumber?.value?.length < 10) {
      setMobileNumber({
        ...mobileNumber,
        error: true,
      });
    }
    if (mobileNumber?.value?.length >= 10) {
      axiosFetch({
        url: "/lead-signin",
        method: "post",
        requestConfig: {
          contact_number: mobileNumber?.value?.replace(/\s+/g, ""),
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              if (res.data.message) {
                setopenSnackbar({
                  open: true,
                  message: res.data.message,
                  serverity: "success",
                });
                setOtpFilde(true);
              }
            }
          } else if (res.status === 400) {
            setopenSnackbar({
              open: true,
              message: res.data.message,
              serverity: "error",
            });
          } else if (res.status === 500) {
            setopenSnackbar({
              open: true,
              message: "Internal server error",
              serverity: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const loginHandler = (e) => {
    setMobileNumber((pre) => {
      return { ...pre, value: e };
    });
  };

  /*   const loginHandler = (e) => {
    console.log(e, "ff");
    let value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setMobileNumber({
        ...mobileNumber,
        value: value,
      });
    }
  }; */

  const isOtpValid = otp.every((digit) => digit !== "");

  const verifyLogin = () => {
    let otpValues = otp.join().replaceAll(",", "");
    if (otpValues.length > 3) {
      axiosFetch({
        url: "/verifyLeadSigninSMS",
        method: "post",
        requestConfig: {
          contact_number: mobileNumber?.value?.replace(/\s+/g, ""),
          code: otpValues,
          device: "web",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              if (res.data.message) {
                setopenSnackbar({
                  open: true,
                  message: res?.data?.message ?? "",
                  serverity: "success",
                });
                let token = res?.data?.body?.token ?? "";
                localStorage.setItem("authTocken", token);
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(res?.data?.body)
                );
                navigate("/", {
                  state: {},
                });
              }
            }
          } else if (res.status === 400) {
            setopenSnackbar({
              open: true,
              message: res?.data?.message,
              serverity: "error",
            });
          } else if (res.status === 500) {
            setopenSnackbar({
              open: true,
              message: "Internal server error",
              serverity: "error",
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    // <Container fixed sx={{ overflow: "hidden", padding: "0px" }}>
    <Grid container sx={{ ...styles.containerArea }}>
      <Grid item xs={12} md={6} sx={{ ...styles.loginArea }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <img src={Fitmom_logo} alt={"logo"} />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", ...styles.headerSpace }}
            >
              <Typography sx={styles.LoginTitle} variant={"body1"}>
                {"This Website is best "}
              </Typography>
              <Typography sx={styles.LoginTitle} variant={"body1"}>
                {"experienced on mobile"}
              </Typography>
            </Grid>

            {!otpFilde ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%", mb: "20px" }}
                >
                  <CustomMuiTelInput
                    autoComplete="off"
                    defaultCountry="IN"
                    value={mobileNumber?.value ? mobileNumber.value : ""}
                    onChange={(e) => loginHandler(e)}
                  />
                  {/* <TextField
                    value={mobileNumber?.value ?? ""}
                    onChange={(e) => loginHandler(e)}
                    inputProps={{
                      maxLength: 10,
                      // style: { textAlign: 'center' },
                    }}
                    sx={styles.mobilenumber}
                    autoComplete="off"
                    id="outlined-basic"
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                  /> */}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Button
                    sx={styles.buttonFiled}
                    disabled={mobileNumber?.value?.length < 15 ? true : false}
                    variant="contained"
                    onClick={LoginWithMobile}
                  >
                    {"Send OTP to My Mobile"}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      autoComplete="off"
                      placeholder="-"
                      sx={styles.mobileTop}
                      id={`otp-input-${index}`}
                      variant="outlined"
                      size="small"
                      value={digit}
                      onChange={handleChange(index)}
                      // onKeyDown={handleKeyDown(index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center" },
                      }}
                    />
                  ))}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Button
                    disabled={!isOtpValid}
                    sx={styles.buttonFiled}
                    variant="contained"
                    onClick={() => verifyLogin()}
                  >
                    {"Verify Otp"}
                  </Button>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Typography
                    sx={styles.backBtn}
                    onClick={() => setOtpFilde(false)}
                    variant="h6"
                  >
                    {"Back"}
                  </Typography>
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item md={6} display={{ xs: "none", md: "block", lg: "block" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={styles.imageAd}
          >
            <img src={Login_demo} alt="demo-image" />
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar.open}
        autoHideDuration={2000}
        onClose={() => setopenSnackbar({ open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setopenSnackbar({ open: false })}
          severity={openSnackbar.serverity}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
    // </Container>
  );
}
