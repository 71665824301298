import { createContext, useContext, useEffect, useState } from "react";

const SelectedCountryContext = createContext();

const SelectedCountryContextProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState("IN");

  return (
    <SelectedCountryContext.Provider
      value={{ selectedCountry, setSelectedCountry }}
    >
      {children}
    </SelectedCountryContext.Provider>
  );
};

export default SelectedCountryContextProvider;

export const useSelectedCountry = () => {
  return useContext(SelectedCountryContext);
};
