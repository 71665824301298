import React, { useState } from "react";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Snackbar,
  Modal,
  Backdrop,
  Fade,
  Box,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./About.css";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosFetch } from "../../Utils/AxiosFetch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "75%",
    md: "50%",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AboutSession() {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackServerity, setSnackServerity] = useState("success");

  let [openBuy, setOpenBuy] = useState(false);

  const {
    session_image,
    session_name,
    duration,
    duration_unit,
    benefits,
    program_id,
    who_should_attend,
    who_should_avoid,
    session_details,
    equipments_required,
    routine,
    coach_name,
    level,
    start_time_label,
    start_date,
    action_label,
    allow_booking,
    allow_reminder,
    join_url,
    booking_id,
    meeting_id,
    id,
  } = state.session;

  const { when } = state;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleBooking = async () => {
    //   action_label === "Reminder set"

    if (action_label === "Booked" || action_label === "Join now") {
      try {
        const response = await axiosFetch({
          url: `/cancelBookingSession/${id}/${meeting_id}`,
          method: "put",
        });

        if (response.status === 200) {
          setOpenSnackbar(true);
          setSnackMessage("Booking cancelled successfully");
          setSnackServerity("success");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (action_label === "Book Now") {
      try {
        const response = await axiosFetch({
          url: `/bookProgramSession`,
          method: "post",
          requestConfig: {
            session_id: id,
            meeting_id: meeting_id,
            program_id: program_id,
          },
        });

        if (response.status === 200) {
          setOpenSnackbar(true);
          setSnackMessage(response.data.message);
          setSnackServerity("success");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else if (response.status === 400) {
          // setSnackMessage(res.data.message);
          //setSnackServerity("error");
          if (response.data.ERR_CODE) {
            if (response.data.ERR_CODE === "MUST_BUY_PROGRAM") {
              setOpenBuy(true);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const joinMeeting = (url) => {
    window.open(url, "_blank");
  };

  const handleVideoStreaming = (session) => {
    let recording;
    let { meeting_id, played_seconds } = session;
    axiosFetch({
      url: `/getProgramSessionPlayUrl?meeting_id=${meeting_id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          recording = res.data.body;
          if (!!res.data.body) {
            navigate("/player", {
              state: {
                recording: recording,
                meetingId: meeting_id,
                played_seconds: played_seconds,
              },
            });
          }
        } else if (res.status === 400) {
          setOpenSnackbar(true);
          setSnackMessage(res.data.message);
          setSnackServerity("error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const handleRemainder = (bookingId) => {
  //   if (bookingId !== null) {
  //     axiosFetch({
  //       url: "/setReminderForProgramSession",
  //       method: "post",
  //       requestConfig: {
  //         booking_id: `${bookingId}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setOpenSnackbar(true);
  //           setSnackMessage(res.data.message);
  //           setSnackServerity("success");
  //           window.location.reload();
  //         } else if (res.status === 400) {
  //           setOpenSnackbar(true);
  //           setSnackMessage(res.data.message);
  //           setSnackServerity("error");
  //         } else if (res.status === 500) {
  //           setSnackMessage("Internal server error");
  //           setSnackServerity("error");
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }
  // };

  const handleYes = async () => {
    try {
      // const leadId = JSON.parse(localStorage.getItem("userDetails"))?.id;
      const response = await axiosFetch({
        url: `v3/updateStage`,
        method: "post",
        // requestConfig: {
        //   leadId,
        // },
      });

      if (response.status === 200) {
        setOpenSnackbar(true);
        setSnackMessage(
          "Thank you for your interest! Our FitMom team will reach out to you shortly."
        );

        setOpenBuy(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNo = () => {
    setOpenSnackbar(true);
    setSnackMessage(
      "No worries! Feel free to explore other features, and we're here whenever you're ready."
    );
    setOpenBuy(false);
  };

  const handleBook = () => {
    switch (action_label) {
      case "Play":
      case "Resume":
        handleVideoStreaming(state.session);
        break;
      // case "Join now":
      //   joinMeeting(join_url);
      //   break;
      default:
        // if (allow_reminder) {
        //   handleRemainder(booking_id);
        // } else {
        //   handleBooking();
        // }
        handleBooking();
        break;
    }
  };

  const handleLable = () => {
    switch (action_label) {
      case "Complete":
        return "Completed";
      case "Booked":
      case "Join now":
      case "Reminder set":
        return "Cancel Booking";
      default:
        return action_label;
    }
  };

  const handleColorBtn = () => {
    switch (action_label) {
      case "Booked":
      case "Join now":
      case "Reminder set":
        return "red";
      default:
        return "black";
    }
  };

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackServerity}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Button
        variant="text"
        startIcon={<ArrowBackIosIcon />}
        display="block"
        gutterBottom
        sx={{
          fontWeight: 500,
          color: "black",
          textTransform: "capitalize",
          fontSize: "14px",
          margin: "16px",
        }}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      <Card elevation={0} sx={{ borderRadius: 0 }}>
        <img
          // style={{
          //   width: "100%",
          //   height: "400px",
          //   objectFit: "contain",
          //   borderRadius: "8px",
          // }}
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
          src={session_image}
        />

        <Box
          sx={{
            px: "20px",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontSize: "15px",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span> {session_name} </span>
              <span> {start_time_label}</span>
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontSize: "12px",
                textTransform: "capitalize",
              }}
            >
              With {coach_name}
              {/* , {level}. */}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "10px",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontSize: "13px",
                  textTransform: "capitalize",
                }}
              >
                <strong>
                  {duration} {duration_unit}
                </strong>
              </Typography>
              <Typography
                variant="subtitle1"
                color="#757575"
                sx={{ marginLeft: "7px", fontSize: "11px" }}
              >
                Duration
              </Typography>
            </Box>

            <hr></hr>

            {/* <div style={{ borderLeft: "10px solid red" }}></div> */}
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: "13px", textTransform: "capitalize" }}
              >
                <strong>{level}</strong>
              </Typography>
              <Typography
                variant="subtitle1"
                color="#757575"
                sx={{ marginLeft: "7px", fontSize: "11px" }}
              >
                Session
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <br />
      <br />
      <Box
        sx={{
          px: "20px",
        }}
      >
        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          About the Session
        </Typography>
        <Typography
          variant="body1"
          color="#757575"
          fontSize="12px"
          fontWeight={400}
          sx={{ mb: "10px" }}
        >
          {session_details}
        </Typography>
        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          What you need for this Session
        </Typography>
        <Typography
          variant="body1"
          color="#757575"
          fontSize="12px"
          fontWeight={400}
          sx={{ mb: "10px" }}
        >
          {/*  style={{ margin: 0, paddingLeft: 0, listStylePosition: "inside" }} */}
          <ul style={{ margin: 0, paddingLeft: "20px" }}>
            {equipments_required.split(",").map((value, index) => (
              <li key={index} style={{ margin: 0 }}>
                {value.trim()}
              </li>
            ))}
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          Benefits of This Session
        </Typography>
        <Typography
          variant="body1"
          color="#757575"
          fontSize="12px"
          fontWeight={400}
          sx={{ mb: "10px" }}
        >
          {/* listStylePosition: "inside" */}
          <ul style={{ margin: 0, paddingLeft: "20px" }}>
            {benefits.split(",").map((value, index) => (
              <li key={index} style={{ margin: 0 }}>
                {value.trim()}
              </li>
            ))}
          </ul>
        </Typography>
        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          Routine
        </Typography>
        <Typography
          variant="body1"
          color="#757575"
          fontSize="12px"
          fontWeight={400}
          sx={{ mb: "5px" }}
        >
          {/*  style={{ margin: 0, paddingLeft: 0, listStylePosition: "inside" }} */}
          <ul style={{ margin: 0, paddingLeft: "20px" }}>
            {routine.split(",").map((value, index) => (
              <li key={index} style={{ margin: 0 }}>
                {value.trim()}
              </li>
            ))}
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          Who Should Attend This Session
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "#757575", mb: "10px" }}
          fontSize="12px"
          fontWeight={400}
        >
          {who_should_attend}
        </Typography>
        <Typography variant="h5" gutterBottom fontSize="14px" fontWeight={600}>
          Who Should Avoid This Session
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "#757575", mb: "5px" }}
          fontSize="12px"
          fontWeight={400}
        >
          {who_should_avoid}
        </Typography>
        <br />
        <br />

        <div className="splitScreen" style={{ textAlign: "center" }}>
          <div className="topPane">
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: 500,
                color: "black",
                fontSize: "14px",
              }}
              onClick={() => navigate("/")}
            >
              View other Sessions
            </Button>
          </div>
          <div
            className="bottomPane"
            style={{
              background: handleColorBtn(),
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: 500,
                color: "white",
                justifyContent: "center",
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
                fontSize: "14px",
                "&:disabled": {
                  color: "white",
                },
              }}
              onClick={handleBook}
              disabled={
                !allow_booking &&
                !allow_reminder &&
                action_label !== "Play" &&
                action_label !== "Resume" &&
                action_label !== "Join now"
              }
            >
              {handleLable()}
            </Button>
          </div>
        </div>
      </Box>
      <Modal
        aria-labelledby="buy-program-modal-title"
        aria-describedby="buy-program-modal-description"
        open={openBuy}
        onClose={() => setOpenBuy(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openBuy}>
          <Box sx={style}>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <Typography id="buy-program-modal-title" sx={{ mt: 2 }}>
                This feature isn't available in your current plan.
              </Typography>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                mt: "20px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#000",
                  color: "#000",
                  px: "25px",
                }}
                onClick={handleNo}
              >
                Maybe Later
              </Button>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
                onClick={handleYes}
              >
                Upgrade Now
              </Button>
            </Box>

            {/* [Yes, Upgrade Now] [No, Maybe Later] */}
            <br />
            {/* <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <ProgramDeepLinkButton close={handleCloseBuy} />
            </Stack> */}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
}
